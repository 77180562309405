// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-collection-js": () => import("/opt/build/repo/src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-update-js": () => import("/opt/build/repo/src/templates/update.js" /* webpackChunkName: "component---src-templates-update-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("/opt/build/repo/src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-user-js": () => import("/opt/build/repo/src/pages/user.js" /* webpackChunkName: "component---src-pages-user-js" */)
}

